<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <Toolbar class="fixed-bottom-toolbar">
        <template slot="right">
          <Button
            :label="$constants.TEXT_BUTTON.BACK"
            @click="backToList()"
            class="p-button-warning"
            style="width: 120px !important;"
          />
          <Button
            v-if="mode == 'edit'"
            :label="$constants.TEXT_BUTTON.EDIT"
            @click="saveData()"
            class="p-button-success"
            style="width: 120px !important;"

          />
          <!-- <Button
            v-else
            :label="$constants.TEXT_BUTTON.ADD"
            @click="saveData()"
            class="p-button-success"
            style="width: 120px !important;"

          /> -->
        </template>
      </Toolbar>
      
      <div class="p-card-field1">
        <div class="p-field p-grid">
          <label class="p-col-fixed "><strong>Tiêu đề</strong><span style="color: red ; padding-left: 1px">*</span></label>
        </div>
      </div>
      <div class="p-card-field1">
        <div class="p-field p-grid">
          <InputText required v-model="title" type="text" id="name_add" class="p-col-width1" placeholder="Nhập tiêu đề"/>
        </div>
      </div>

      <div class="p-card-field1">
        <div class="p-field p-grid">
          <label class="p-col-fixed "><strong>Nội dung</strong><span style="color: red ; padding-left: 1px">*</span></label>
        </div>
      </div>
      <div class="p-card-field1">
        <div class="p-field p-grid">
          <input class="input_tmp_validator" id="validator_content">
          <CkeditorCode :contentSet="this.content" @newdata="handleDataCkditor($event,'content')"></CkeditorCode>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
// import DataServices from '@/core/DataServices'
import CkeditorCode from '../CkeditorCode';
export default {
  components: {
    CkeditorCode
  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      policy_content:'', 
      content: '',
      title: '',
      ordinal: 1,
      validationErrors: {},
      formMode: this.mode,
      firebaseID: null,
      userID: null,
      role: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
      images: [],
      imagesDeletes: [],
      imgDelete : [],
      maxFileSize: 1000000, // 1MB
      maxFiles: 1,
      saving:false,
    }
  },
  async mounted() {
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      console.log("this.model*****************",this.model)
      this.content = this.model.content
      this.title = this.model.title
    }
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.EDIT_POLICY);
    this.role.edit = (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) ? false:true;

    // var that =this;
      if(this.model.relationship_file && this.model.relationship_file.length > 0){
        for (let i = 0; i < this.model.relationship_file.length; i++) {
          const e = this.model.relationship_file[i];
          if(e.type == 'POLICY'){
            if (e.file && e.file.url) {
              let url = e.file.url
              let split = url.split('/')
              let fileName = split[split.length - 1]
              let obj = {'name': fileName, 'file': '', 'objectURL': url,type:'old_edit'}//type:'old_edit' nếu gặp type này ko update avatar
              this.images.push(obj)
            }
          }
        }
      }

  },
  methods: {
    handleDataCkditor: function(e,key) {
      var dataContent = (e.length) ? e[0]:'';
      if (key == 'content'){
        this.content = dataContent;
      }
    }
    ,
    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};
      let name_add = document.getElementById("name_add");
      if (this.title == undefined || this.title == null || this.title == ''){
        name_add.setCustomValidity("Nhập tiêu đề!");
      }else {
        name_add.setCustomValidity("");
      }
      if (this.$commonFuction.isEmpty(this.content)) {
        document.getElementById("validator_content").setCustomValidity("Nhập nội dung!");
      } else {
        document.getElementById("validator_content").setCustomValidity("");
      }
      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },
    async saveData() {
      // this.$commonFuction.is_loading(true);
       if (await this.validateData()) {
        // this.saving = false;
       // this.$commonFuction.is_loading(false);
        return
      }
      if(Object.entries(this.validationErrors).length === 0){
        var params = {
          content: this.content,
          title: this.title, 
          images: this.images,
          imagesDeletes: this.imgDelete,
        }
        this.$emit('save', params);
      }else{
        this.$commonFuction.is_loading(false);
      }
    }, 
    isImage(file) {
      return /^image\//.test(file.type);
    },
    async onFileSelect(event) {
      let files = event.target.files || event.dataTransfer.files;
      if(files.length > 0) {
        let acceptTags = ['jpeg', 'jpg', 'gif', 'png', 'JPEG', 'JPG', 'PNG', 'GIF']
        for (let i = 0; i < files.length; i++) {
          if (!acceptTags.includes(files[i].name.split('.').pop())) {
            alert("Vui lòng upload file ảnh có định dạng sau: jpeg, jpg, gif, png");
            return;
          }
        }
        if (files.length + this.images.length > this.maxFiles) {
          alert("Hình ảnh quá số lượng tối đa là " + this.maxFiles + " hình");
        } else {
          var arrTemp = [], arrFiles = [];
          for (let file of files) {
            if (this.isImage(file) && file.size <= this.maxFileSize) {
              file.source = file;
              file.objectURL = window.URL.createObjectURL(file);
              arrTemp.push(file);
            }
            if (file.size > this.maxFileSize) arrFiles.push(file);
          }
          
          if (arrFiles.length > 0) {
            alert("Hình ảnh của bạn đã quá dung lượng 1MB");
          } else {
            if (this.images.length > 0 && this.images[0].file === '') {
              this.imagesDeletes.push(this.images[0])
            }
            // this.images = [];
            this.images = this.images.concat.apply(this.images, arrTemp);
            // console.log(this.images)
            // await this.validateData()
          }
        }
      }
    },
    onFileClear(index) {
      let img = this.images[index];
      this.images.splice(index, 1);
      if (img.file === '') {
        this.imgDelete.push(img)
      }
    }
  },
  apollo: {
    
  }
}
</script>

<style lang="scss">
  .p-invalid {
    color: red;
    margin: 1px 0px 0px 0px;
  }
  .p-multiselect {
    width: 450px;
  }
  .p-multiselect-label:not(.p-placeholder) {
    padding-top: .25rem;
    padding-bottom: .25rem;
    
  }
  .category-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .2rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
  }
  .p-multiselect-label-container{
    height: 40px;
  }
  .p-invalid {
    color: red;
    margin: 1px 0px 0px 0px;
  }
  .text-active{
    margin: 0px 0px 0px 8px;
  }
  .p-formgrid >.p-card-field1 >.p-field >.p-col-width1 >.p-dropdown-panel{
    left : 0px !important
  }
  .p-col-width1 {
    width: 100%;
  }

  </style>
