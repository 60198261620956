<template>
  <div class="p-fluid">
    <div class="p-col-12 p-lg-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <formEdit
          :key="componentKey"
          :mode="mode"
          @back="backToList"
          @save="saveData"
          :model="models"/>
      </div>
    </div>
  </div>
</template>

<script>

import DataServices from '@/core/DataServices'
import formEdit from './add'
export default {
  components: {
    formEdit
  },
  data() {
    return {
      validationErrors: [],
      title: "Cập nhật chính sách",
      mode: 'add',
      models: {},
      images: [],
      oldImages: [],
      componentKey: 0,
      page_transaction:null,
      role: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      }
    }
  },
  async mounted () {
    // console.log("this.$route.name:",this.$route.query.page_transaction);
    this.mode = this.$route.name === 'editPolicy' ? 'edit' : this.mode;
    this.title = this.mode === 'edit' ? 'Chỉnh sửa' : this.title;
    if (this.$route.params.id !== undefined) await this.getData(this.$route.params.id);
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
      }
    }
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.EDIT_POLICY);
    if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0){
      this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    }

  },
  methods: {
    backToList() {
    this.$router.push({ path: '/admin/list-policy' });
    },
    async saveData(params) {
      // console.log("params",params)
      console.log("paramsfuntion.....",params)
      this.images = (params.images !== undefined && params.images !== null) ? params.images : [];
      var that = this;
      if(this.mode === 'edit'){
        let objects = {
          'content': params.content,
          'title': params.title,
        }
        let variables = {
          'data_update': objects,
          'where_key': {
            'id': {'_eq': this.models.id}
          }
        };
        this.$apollo.mutate({
          mutation: DataServices.updateData('policy'),
          variables: variables
        }).then(async (res) => {
          if (res.data[Object.keys(res.data)].affected_rows) {
            // var returning = res.data[Object.keys(res.data)].returning[0]; 
            var policy_id = this.$route.params.id;
            //cập nhật hình ảnh
            if (params.images && params.images.length > 0){
            var type_image = params.images[0].type;
            //check hình ảnh có phải hình cũ ko || nếu hình cũ thì ko update
            if (type_image != 'old_edit') {
              //xóa hình ảnh record nếu có sự thay đổi
              await that.$CoreService.deleteDataImage(that.$constants.TYPE_IMAGE.POLICY, policy_id).then(async(resDelete) => {
                console.log("resresres:", resDelete);
                await that.$CoreService.uploadImages(that.$constants.FOLDER_S3.POLICY, params.images).then(async(resImage) => {
                  console.log("resresres:", resImage);
                  if (resImage) {
                    var objectImage = resImage[0];//lấy 1 tấm hình
                    await that.$CoreService.updateDataImage(that.$constants.TYPE_IMAGE.POLICY, policy_id, objectImage).then(() => {
                    });
                  }
                });
              });
            }
          }
          else{
            await that.$CoreService.deleteDataImage(that.$constants.TYPE_IMAGE.POLICY, policy_id).then(async(resDelete) => {
                console.log("resresres:", resDelete);
            })
          }
          }

        }).then(() => {
          this.redirectEdit();
          // this.$commonFuction.is_loading(false);
          // this.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Cập nhật thành công', life: 3000 })
        });



      }
    },
    async redirectEdit() {
      var that = this;
      that.$commonFuction.is_loading(false);
      var router_back = {path: '/admin/list-policy'};
      that.$router.push(router_back);
      that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
    },
    async getData(id) {
      var {data} = await this.$apollo.query({
        query: DataServices.getList('policy',{"fields" : "id title content type created_at updated_at relationship_file {id type file {id url}}"}),
        variables: {
          where_key: {
            id: {'_eq': id}
          }
        },
        fetchPolicy: "network-only"
      })
      var model = data[Object.keys(data)];
      if (model.length > 0) {
        this.models = model[0];
      }
      this.componentKey++;
    },

  },
  apollo: {
    
  }
}
</script>

<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.note{ letter-spacing: 0px; color: #707070; margin-bottom: 24px; padding-top: 10px;}
.p-formgrid { margin: 0;
  // .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
  .p-card-field{ padding: 0px 24px 0px 24px;
    .p-field{
      .p-col-fixed { width: 300px; margin: 0;}
      .p-col-width{width:100% !important; border-radius: 5px;
        &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px;}
        .file-select{margin: 0;
          input[type="file"]{display: none;}
          .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
        }
        &.pac-target-input{ height: 80px;  word-break: break-all; overflow-wrap: break-word;  resize: none;}
      }
      .box-images{ margin: 0; margin-top: 10px; position: relative;
        .item-img{width: 133px; height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative;
          img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain; padding: 5px;}
          .button-clear{border-radius: 5px; width: 24px; height: 24px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
            img{vertical-align: top;}
          }
        }
      }
      .p-invalid{display: block;}
    }
  }


  .fixed-bottom-toolbar{background: transparent; border: 0 none; margin-top: 5px; padding: 0; padding-top: 0px;
    .p-button{width: auto; margin-right: 8px;
      &:last-child{margin-right: 0;}
    }
  }
}
</style>